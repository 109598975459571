"use client";

import React, { FormEvent, use, useEffect, useState } from "react";
import { withMask } from "use-mask-input";

import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn, reverseDateFormat } from "@/lib/utils";
import { Radio } from "@/components/ui/radio";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { useReCaptcha } from "next-recaptcha-v3";
import { useStore } from "@/store";
import { usePostalCode } from "@/hooks/usePostalCode";

const DynamicForm = ({
  fields,
  defaultValues,
  textNotWhite,
  onSubmit,
  buttonLabel,
  extraInfo,
  sendMessage,
  serverError,
  keepForm,
  buttonBackgroundColor,
  buttonFit,
}: any) => {
  const [formErrors, setFormErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [send, setSend] = useState(false);
  const [error, setError] = useState(false);
  const { executeRecaptcha } = useReCaptcha();

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.currentTarget);
    const errors = validateForm(formData);

    if (errors.length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    const token = await executeRecaptcha("form_submit");

    const verified = await fetch("/api/verify", {
      method: "POST",
      body: JSON.stringify({
        data: {
          token: token,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!verified.ok) {
      setError(true);
      setLoading(false);
      return;
    }

    onSubmit(formData)
      .then(() => {
        setLoading(false);
        setSend(true);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }

  function validateForm(formData: FormData) {
    const errors = [] as any[];

    fields.forEach((field: any) => {
      if (field.type === "checkbox" && field.required && formData.get(field.id) !== "on") {
        errors.push({
          id: field.id,
          message: field.errorMessage,
        });
      } else if (field.required && formData.get(field.id) === "") {
        errors.push({
          id: field.id,
          message: field.errorMessage,
        });
      } else if (field.type === "tel") {
        const tel = formData.get(field.id);
        if (tel && tel.toString().replace("_", "").length !== 10) {
          errors.push({
            id: field.id,
            message: field.errorMessage,
          });
        }
      } else if (field.id === "birthdate" && formData.get(field.id) !== "") {
        const age = new Date().getFullYear() - new Date(reverseDateFormat(formData.get(field.id))).getFullYear();
        if (age < 14 || (age > 30 && formData.get("membershipType") !== "nsc")) {
          errors.push({
            id: "membershipType",
            message: "Je moet tussen 14 en 30 jaar oud zijn om lid te worden van JSC",
          });
        }
      }
    });

    return errors;
  }

  const { loading: postalCodeLoading, error: postalCodeError } = usePostalCode();

  const globalBirthdate = useStore((state) => state.globalBirthdate);
  const setGlobalBirthdate = useStore((state) => state.setGlobalBirthdate);

  let ageIsBetween14And30 = true;

  if (globalBirthdate) {
    const age = new Date().getFullYear() - new Date(reverseDateFormat(globalBirthdate)).getFullYear();
    ageIsBetween14And30 = age >= 14 && age <= 30;
  }

  return send && !keepForm ? (
    <div className="flex flex-col gap-4">
      <p className={cn("body-xl", !textNotWhite ? "text-white" : "text-nsc-dark-blue")}>{sendMessage}</p>
    </div>
  ) : (
    <form onSubmit={handleSubmit}>
      {(error || serverError) && (
        <div className="col-span-1 grid w-full items-center gap-1.5">
          <p className="">Er is een fout opgetreden. Probeer het later nog eens.</p>
        </div>
      )}
      <div className="grid grid-cols-12 gap-6">
        {fields.map((field: any) => {
          switch (field.type) {
            case "text":
            case "email":
              return (
                <InputField
                  defaultValue={defaultValues?.[field.id]}
                  textNotWhite={textNotWhite}
                  key={field.id}
                  {...field}
                  errors={formErrors}
                />
              );
            case "date":
              return (
                <div key={field.id} className={cn("col-span-12 grid w-full items-center gap-1.5", field.colSpan)}>
                  <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={field.id}>
                    {field.label}
                  </Label>
                  <Input
                    ref={withMask("99-99-9999")}
                    pattern={field.pattern}
                    readOnly={field.readOnly}
                    placeholder="DD-MM-YYYY"
                    type="text"
                    id={field.id}
                    name={field.id}
                    onBlur={(e) => {
                      if (field.id === "birthdate") setGlobalBirthdate(e.target.value);
                    }}
                    autoComplete={field.autoComplete}
                    defaultValue={defaultValues?.[field.id]}
                  />
                  {formErrors.find((error: { id: any }) => error.id === field.id) && (
                    <p className={cn(textNotWhite ? "text-sm text-red-600" : "text-sm text-red-600")}>
                      {formErrors.find((error: { id: any }) => error.id === field.id).message}
                    </p>
                  )}
                </div>
              );
            case "tel":
              return (
                <div key={field.id} className={cn("col-span-12 grid w-full items-center gap-1.5", field.colSpan)}>
                  <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={field.id}>
                    {field.label}
                  </Label>
                  <Input
                    // pattern="[0-9]{10}"
                    ref={withMask("9999999999")}
                    readOnly={field.readOnly}
                    type="text"
                    id={field.id}
                    name={field.id}
                    autoComplete={field.autoComplete}
                    defaultValue={defaultValues?.[field.id]}
                  />
                  {formErrors.find((error: { id: any }) => error.id === field.id) && (
                    <p className={cn(textNotWhite ? "text-sm text-red-600" : "text-sm text-red-600")}>
                      {formErrors.find((error: { id: any }) => error.id === field.id).message}
                    </p>
                  )}
                </div>
              );
            case "textarea":
              return (
                <TextField
                  defaultValue={defaultValues?.[field.id]}
                  textNotWhite={textNotWhite}
                  key={field.id}
                  {...field}
                />
              );
            case "checkbox":
              return (
                <CheckboxField
                  defaultValue={defaultValues?.[field.id]}
                  textNotWhite={textNotWhite}
                  key={field.id}
                  {...field}
                  errors={formErrors}
                />
              );
            case "select":
              return (
                <SelectField
                  defaultValue={defaultValues?.[field.id]}
                  textNotWhite={textNotWhite}
                  key={field.id}
                  {...field}
                  errors={formErrors}
                />
              );
            case "radio":
              return (
                <div key={field.id} className={cn("col-span-12 grid w-full items-center gap-1.5", field.colSpan)}>
                  <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={field.id}>
                    {field.label}
                    {field.description ? (
                      <>
                        <br />
                        <br />
                        <span className="text-xs">{field.description}</span>
                      </>
                    ) : null}
                  </Label>
                  <RadioGroup.Root
                    name={field.id}
                    disabled={field.disabled}
                    defaultValue={defaultValues?.[field.id]}
                    className="flex flex-col gap-2"
                  >
                    {field.options.map((option: any) => (
                      <div key={option.id} className="flex items-center">
                        <Radio
                          id={option.id}
                          value={option.id}
                          className="RadioGroupItem"
                          aria-labelledby={option.label}
                          disabled={(option.id === "jsc" || option.id === "combo") && !ageIsBetween14And30}
                        />
                        <label htmlFor={option.id} className={cn("ml-2", textNotWhite ? "" : "text-white")}>
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </RadioGroup.Root>
                  {formErrors.find((error: { id: any }) => error.id === field.id) && (
                    <p className={cn(textNotWhite ? "text-sm text-red-600" : "text-sm text-red-600")}>
                      {formErrors.find((error: { id: any }) => error.id === field.id).message}
                    </p>
                  )}
                </div>
              );
            default:
              return null;
          }
        })}
        <div className="col-span-12 my-4 grid w-full items-center gap-1.5">
          <Button
            className={cn(buttonFit ? "mx-auto w-full max-w-[380px]" : "w-full")}
            disabled={loading}
            type="submit"
            variant={buttonBackgroundColor}
          >
            {loading ? "Versturen..." : buttonLabel}
          </Button>
        </div>
        {extraInfo && (
          <div className="col-span-12 grid w-full items-center gap-1.5">
            <p className="font-roboto text-sm font-light text-white">{extraInfo}</p>
          </div>
        )}
      </div>
    </form>
  );
};

const InputField = ({
  id,
  label,
  type = "text",
  textNotWhite,
  pattern,
  autoComplete,
  errors,
  readOnly,
  defaultValue,
  colSpan,
}: any) => {
  const [value, setValue] = useState(defaultValue);
  const globalLocation = useStore((state) => state.globalLocation);
  const setGlobalHouseNumber = useStore((state) => state.setGlobalHouseNumber);
  const setGlobalPostalCode = useStore((state) => state.setGlobalPostalCode);
  const setGlobalBirthdate = useStore((state) => state.setGlobalBirthdate);

  useEffect(() => {
    if (globalLocation) {
      if (id === "city") setValue(globalLocation?.city);
      if (id === "street") setValue(globalLocation?.street);
      if (id === "municipality") setValue(globalLocation?.municipality);
      if (id === "province") setValue(globalLocation?.province);
    }
  }, [globalLocation, id]);

  const handleSetGlobalValues = (e: any) => {
    if (id === "postalCode") {
      setGlobalPostalCode(e.target.value);
    } else if (id === "houseNumber") {
      setGlobalHouseNumber(e.target.value);
    }
  };

  return (
    <div className={cn("col-span-12 grid w-full items-center gap-1.5", colSpan)}>
      <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={id}>
        {label}
      </Label>
      <Input
        pattern={pattern}
        readOnly={readOnly}
        type={type}
        id={id}
        name={id}
        onBlur={(e) => handleSetGlobalValues(e)}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
        autoComplete={autoComplete}
        value={value}
      />
      {errors.find((error: { id: any }) => error.id === id) && (
        <p className={cn(textNotWhite ? "text-sm text-red-600" : "text-sm text-red-600")}>
          {errors.find((error: { id: any }) => error.id === id).message}
        </p>
      )}
    </div>
  );
};

const TextField = ({ id, label, textNotWhite, defaultValue, colSpan }: any) => (
  <div className={cn("col-span-12 grid w-full items-center gap-1.5", colSpan)}>
    <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={id} defaultValue={defaultValue}>
      {label}
    </Label>
    <Textarea id={id} name={id} />
  </div>
);

const CheckboxField = ({ id, label, textNotWhite, defaultValue, errors, colSpan, required }: any) => (
  <>
    <div className={cn("col-span-12 flex items-start justify-start", colSpan)}>
      <Checkbox
        id={id}
        name={id}
        defaultChecked={defaultValue}
        className={cn(textNotWhite && "border-nsc-dark-blue")}
      />
      <Label
        dangerouslySetInnerHTML={{
          __html: label,
        }}
        className={cn("ml-2 text-white", textNotWhite ? "text-nsc-dark-blue" : "text-white")}
        htmlFor={id}
      />
    </div>
    {errors.find((error: { id: any }) => error.id === id) && (
      <p className={cn(textNotWhite ? "col-span-12 text-sm text-red-600" : "col-span-12 text-sm text-red-600")}>
        {errors.find((error: { id: any }) => error.id === id).message}
      </p>
    )}
  </>
);

export const SelectField = ({ id, label, errors, defaultValue, textNotWhite, options, colSpan }: any) => (
  <div className={cn("col-span-12 grid w-full items-center gap-1.5", colSpan)}>
    <Label className={cn(textNotWhite ? "" : "text-white")} htmlFor={id}>
      {label}
    </Label>
    <Select name={id} defaultValue={defaultValue}>
      <SelectTrigger className="h-[48px] w-full border border-nsc-dark-blue bg-white text-sm font-normal">
        <SelectValue placeholder="Selecteer" />
      </SelectTrigger>
      <SelectContent>
        {options.map((option: any) => (
          <SelectItem value={option.id} key={option.id}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
    {errors.find((error: { id: any }) => error.id === id) && (
      <p className={cn(textNotWhite ? "mt-1 text-sm text-red-600" : "mt-1 text-sm text-red-600")}>
        {errors.find((error: { id: any }) => error.id === id).message}
      </p>
    )}
  </div>
);

export default DynamicForm;
