"use client";

import Image from "next/image";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/components/ui/carousel";

interface AnimatedCardsSectionProps {
  data?: any;
}

// TODO: Fix animation when 3 cards
const AnimatedCardsSection = ({ data }: AnimatedCardsSectionProps) => {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  return (
    <section className={`relative h-full py-16 md:py-24 ${data.type === "three-cards-grid" ? "bg-[#F5F5F5]" : ""}`}>
      <div className="grid-container container col-span-12 gap-14 md:gap-16">
        <div className="col-span-12 flex flex-col justify-center gap-5 md:col-span-12">
          <h2
            className={`${data.type === "three-cards-grid" ? "text-center" : ""} header-2 text-jsc-sky-blue md:!text-[81px]`}>
            {data.title}
          </h2>
        </div>
        <div className={cn(
          "col-span-12 hidden grid-cols-1 gap-5 md:grid",
          data.type === "three-cards-grid" && "md:grid-cols-3",
          data.type === "four-cards-grid" && "md:grid-cols-2 lg:grid-cols-4",
        )}>
          {data.cards.map((item: any) => (
            <Card key={item.id} item={item} />
          ))}
        </div>

        <Carousel
          opts={{
            align: "start",
          }}
          className="col-span-12 w-screen md:hidden"
          setApi={setApi}
        >
          <CarouselContent className="-ml-5 mb-12">
            {data.cards.map((item: any) => (
              <CarouselItem key={item.id} className={"basis-[90%] pl-5 md:basis-[33.333%] lg:basis-[25%]"}>
                <Card item={item} />
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="flex w-[calc(100vw-20px)] items-center justify-between md:w-full">
            <CarouselPrevious arrowColor="white" className="bg-jsc-sky-blue" />
            <div className="flex justify-center gap-2">
              {count > 1 &&
                Array.from({ length: count }).map((_, index) => (
                  <motion.button
                    aria-label={`Scroll naar slide ${index + 1}`}
                    onClick={() => api?.scrollTo(index)}
                    animate={{ opacity: current === index + 1 ? 1 : 0.5 }}
                    key={index}
                    className="h-4 w-4 rounded-full bg-jsc-sky-blue p-0"
                  />
                ))}
            </div>
            <CarouselNext arrowColor="white" className="bg-jsc-sky-blue [&>svg]:!fill-white [&>svg]:stroke-white" />
          </div>
        </Carousel>
      </div>
    </section>
  );
};

const Card = ({ item }: any) => {
  const [hovered, setHovered] = useState(false);
  const header = useRef<HTMLHeadingElement>(null);
  const [offsetHighest, setOffsetHighest] = useState(0);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    // find all the card headers and set the offset of the heighest one
    if (!header.current) return;
    const headers = document.querySelectorAll(".card-header");
    if (headers.length > 0) {
      let highest = 0;
      headers.forEach((header: any) => {
        if (header.getBoundingClientRect().height > highest) {
          highest = header.getBoundingClientRect().height;
        }
      });

      setOffsetHighest(highest);
      setOffset(header.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <article
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="relative col-span-1 h-[500px] overflow-hidden rounded-[27px] md:h-[600px]"
    >
      <div className="absolute bottom-0 z-20 h-[50%] w-full bg-gradient-to-t from-[rgba(0,0,0,0.4)] to-transparent" />

      <figure className="absolute z-10 h-full w-full bg-gray-50">
        <Image
          src={strapiMediaUrl(item.backgroundImage)}
          fill
          alt={strapiMediaAlt(item.backgroundImage)}
          sizes="(max-width: 768px) 100vw, 1200px"
          className="object-cover object-center"
        />
      </figure>

      <motion.div
        initial={false}
        animate={{
          opacity: 1,
          y: hovered ? 0 : `calc(100% - ${offsetHighest + offset - 0}px)`,
          backgroundColor: !hovered ? "rgba(0, 123, 184, 0)" : "var(--color-jsc-sky-blue)",
        }}
        transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
        className="absolute bottom-0 left-0 z-30 flex h-fit w-full translate-y-full flex-col items-center justify-between p-6 text-white md:p-6 md:py-8"
      >
        <div className="flex flex-col gap-4">
          <h3 ref={header} className="header-2 card-header !text-[32px] !leading-[36px] md:!leading-[36px]">
            {item.title}
          </h3>
          <motion.div
            className="flex flex-col gap-2"
            animate={{
              y: hovered ? 0 : offsetHighest + 50,
            }}
            transition={{ duration: 1, ease: [0.16, 1, 0.3, 1] }}
          >
            <p className="body">{item.description}</p>
            {item.url && (
              <Link className="underline underline-offset-2" href={item.url}>
                Lees meer
              </Link>
            )}
          </motion.div>
        </div>
      </motion.div>
    </article>
  );
};

export default AnimatedCardsSection;
