import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Speerpunt, TagVariant } from "@/lib/types";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function reverseDateFormat(date: any) {
  if (!date) return "";
  // Check if the format is YYYY-MM-DD or DD-MM-YYYY by looking at the first part
  const dateParts = date.split("-");

  if (dateParts[0].length === 4) {
    // If the first part has 4 digits, it's in YYYY-MM-DD format
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  } else {
    // Otherwise, it's in DD-MM-YYYY format
    return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  }
}

export function getUniqueTags(data: Speerpunt[]): TagVariant[] {
  const tagsSet: Set<TagVariant> = new Set();
  data.forEach((item) => {
    item.attributes.tags.forEach((tag) => {
      tagsSet.add(tag as any);
    });
  });
  return Array.from(tagsSet);
}

export function strapiMediaUrl(image: any) {
  if (!Array.isArray(image?.data) && !image?.data) return image?.attributes?.url;

  if (image?.data?.length > 0) {
    return image?.data.map((img: any) => {
      return img?.attributes?.url;
    });
  }
  if (image?.data?.attributes?.url === undefined) return null;
  return image?.data?.attributes?.url;
}

export function strapiMediaAlt(image: any) {
  if (!Array.isArray(image?.data) && !image?.data) return image?.attributes?.alternativeText;

  if (image?.data?.length > 0) {
    return image?.data.map((img: any) => {
      return img?.attributes?.alternativeText;
    });
  }
  if (image?.data?.attributes?.alternativeText === undefined || image?.data?.attributes?.alternativeText === null)
    return "no alt text";
  return image?.data?.attributes?.alternativeText;
}

export function videoDetector(url: string) {
  const videoExtensions = [
    "mp4",
    "webm",
    "ogg",
    "mov",
    "avi",
    "wmv",
    "flv",
    "mkv",
    "3gp",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
    "m4v",
    "m4a",
    "f4v",
    "f4a",
    "m4b",
    "m4r",
    "f4b",
    "m4p",
  ];
  const extension = url?.split(".").pop() || "";
  return videoExtensions.includes(extension);
}

export function getBgColor(color: string) {
  switch (color) {
    case "white":
      return "#ffffff";
    case "black":
      return "#000000";
    case "nsc-yellow":
    case "jsc-yellow":
      return "#ffd000";
    case "nsc-dark-blue":
      return "#171c60";
    case "nsc-europe-blue":
      return "#01339b";
    case "nsc-light-gray":
      return "#f2f2f2";
    case "nsc-light-blue":
      return "#79a7e9";
    case "nsc-ice-blue":
      return "#d1e3f1";
    case "nsc-mint":
      return "#79c6bd";
    case "nsc-dark-brown":
      return "#583f31";
    case "nsc-orange":
      return "#f47512";
    case "jsc-sky-blue":
      return "#007bb8";
    case "jsc-medium-gray":
      return "#d9d9d9";
    case "jsc-dark-gray":
      return "#303338";
    default:
      return "#ffffff";
  }
}

export function getTextColorByBgColor(color: string | undefined | null = null) {
  if (!color) {
    // Primary text color for the website
    switch (process.env.NEXT_PUBLIC_WEBSITE_ID) {
      case "nsc":
        return "#171c60"; // nsc-dark-blue
      case "jsc":
        return "#303338"; // black
      default:
        return "#000000"; // black
    }
  }

  switch (color) {
    case "white":
      return process.env.NEXT_PUBLIC_WEBSITE_ID === "nsc" ? "#171c60" : "#000000"; // black
    case "black":
      return "#ffffff"; // white
    case "nsc-yellow":
      return "#171c60"; // nsc-dark-blue
    case "nsc-dark-blue":
      return "#ffffff"; // white
    case "nsc-europe-blue":
      return "#ffffff"; // white
    case "nsc-light-gray":
      return "#171c60"; // nsc-dark-blue
    case "nsc-light-blue":
      return "#171c60"; // nsc-dark-blue
    case "nsc-ice-blue":
      return "#171c60"; // nsc-dark-blue
    case "nsc-mint":
      return "#171c60"; // nsc-dark-blue
    case "nsc-dark-brown":
      return "#ffffff"; // white
    case "nsc-orange":
      return "#ffffff"; // white
    case "jsc-yellow":
      return "#000000"; // black
    case "jsc-sky-blue":
      return "#ffffff"; // black
    case "jsc-medium-gray":
      return "#000000"; // black
    case "jsc-dark-gray":
      return "#ffffff"; // white
    case "jsc-sky-blue-white": // jsc-sky-blue as background color and white as text color
      return "#ffffff"; // white
  }
}
