"use client";

import { cva } from "class-variance-authority";
import { AnimatePresence, motion } from "framer-motion";

import NewsFilter from "@/components/general/NewsFilter";
import Card from "@/components/general/Card";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";

export const getVariantByCollection = (collection: string) => {
  switch (collection) {
    case "speerpunten":
      return "default";
    case "nieuws":
      return "nsc-mint";
    case "agenda":
      return "nsc-mint";
    case "Over ons":
      return "default";
    default:
      return "default";
  }
};

const gridVariants = cva("", {
  variants: {
    sizes: {
      two_cards_slider: "col-span-12 sm:col-span-6 md:col-span-6",
      three_cards_slider: "col-span-12 sm:col-span-6 md:col-span-4",
      four_cards_slider: "col-span-12 sm:col-span-6 md:col-span-3",
      three_cards_grid: "col-span-12 sm:col-span-6 md:col-span-4",
      four_cards_grid: "col-span-12 sm:col-span-6 md:col-span-3",
      two_cards_grid: "col-span-12 sm:col-span-6 md:col-span-6",
    },
  },
  defaultVariants: {
    sizes: "three_cards_slider",
  },
});

const PaginatedArchiveSection = ({ data }: any) => {
  let { items, uniqueTags, pagination, activeFilter, collection, variant } = data;

  const renderPaginationItems = () => {
    let items = [];
    let startPage = Math.max(1, pagination.page - 2);
    let endPage = Math.min(pagination.pageCount, pagination.page + 2);

    if (endPage - startPage < 4) {
      endPage = Math.min(pagination.pageCount, startPage + 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink
            style={{ opacity: i === pagination.page ? 1 : 0.2 }}
            variant={getVariantByCollection(collection)}
            className="h-4 w-4 rounded-full p-0"
            href={`/nieuws?page=${i}`}
          />
        </PaginationItem>
      );
    }

    return items;
  };

  return (
    <div>
      {collection === "nieuws" && (
        <div className="mx-auto mb-12 flex w-full gap-8 md:mb-16">
          <h2 className="sr-only">Filter op categorie</h2>
          <NewsFilter uniqueTags={uniqueTags} activeFilter={activeFilter} />
        </div>
      )}

      <div className="mb-16 grid grid-cols-12 gap-x-5 gap-y-14">
        <AnimatePresence initial={true} mode="wait">
          {items && items.length > 0 ? (
            items.map((item: any) => (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: [0.16, 1, 0.3, 1] }}
                className={gridVariants({ sizes: data.type })}
                key={`${item.id}-${activeFilter}`}
              >
                <Card square urlVariant={collection} variant={variant} item={item} />
              </motion.div>
            ))
          ) : (
            <div key={"empty"} className="col-span-12">
              <p className="header-5 text-center">Er zijn geen resultaten gevonden.</p>
            </div>
          )}
        </AnimatePresence>
      </div>

      {pagination && items.length > 0 && pagination.pageCount > 1 && (
        <Pagination className="w-full">
          <PaginationContent className="w-full justify-between">
            <PaginationItem>
              <PaginationPrevious
                disabled={pagination.page === 1}
                variant={getVariantByCollection(collection)}
                href={`/nieuws?page=${pagination.page - 1}`}
              />
            </PaginationItem>
            <div className="flex items-center gap-2">{renderPaginationItems()}</div>
            <PaginationItem>
              <PaginationNext
                disabled={pagination.page === pagination.pageCount}
                variant={getVariantByCollection(collection)}
                href={`/nieuws?page=${pagination.page + 1}`}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}
    </div>
  );
};

export default PaginatedArchiveSection;
