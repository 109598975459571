"use client";

import { useEffect, useState } from "react";

import RichText from "@/components/general/nsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import DynamicForm from "@/components/general/DynamicForm";
import { getProvinces } from "@/lib/api/teamleader";
import { reverseDateFormat } from "@/lib/utils";
import { toast } from "sonner";
import { useRouter } from "next/navigation";

const BecomeAMemberSection = ({ data }: any) => {
  const router = useRouter();
  const [email, setEmail] = useState<any>("");
  const [provinces, setProvinces] = useState({
    data: [],
  });

  useEffect(() => {
    getProvinces().then((data) => {
      setProvinces(data);
    });
  }, []);

  const handleSubmit = async (formData: FormData) => {
    const mobileNumber = formData.get("mobilePhone");
    const rawFormData = {
      email: formData.get("email"),
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      gender: formData.get("gender"),
      street: formData.get("street"),
      houseNumber: formData.get("houseNumber"),
      postalCode: formData.get("postalCode"),
      birthdate: reverseDateFormat(formData.get("birthdate")),
      city: formData.get("city"),
      areaLevelTwoId: formData.get("province"),
      mobileNumber: mobileNumber,
      phoneNumber: formData.get("phoneNumber"),
      municipality: formData.get("municipality"),
      membershipType: formData.get("membershipType"),
      marketingMailsConsent: formData.get("newsletter") === "on" ? true : false,
    };

    setEmail(formData.get("email"));

    await fetch(`${`/api/auth/create-account`}`, {
      method: "POST",
      body: JSON.stringify(rawFormData),
    })
      .then((response) => {
        if (response.ok) {
          router.push("/mijn-account/inloggen/account-created");
        } else {
          toast.error("Er is een fout opgetreden. Probeer het later nog eens.");
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Er is een fout opgetreden. Probeer het later nog eens.");
      });
  };

  const formFields = [
    {
      id: "firstName",
      label: "Voornaam*",
      type: "text",
      required: true,
      errorMessage: "Voornaam is verplicht in te vullen",
      colSpan: "md:col-span-6",
    },
    {
      id: "lastName",
      label: "Achternaam*",
      type: "text",
      required: true,
      errorMessage: "Achternaam is verplicht in te vullen",
      colSpan: "md:col-span-6",
    },
    {
      id: "email",
      label: "E-mail*",
      type: "email",
      required: true,
      errorMessage: "E-mail is verplicht in te vullen",
      autoComplete: "email",
      colSpan: "md:col-span-6",
    },
    {
      id: "gender",
      label: "Geslacht*",
      type: "select",
      required: true,
      errorMessage: "Geslacht is verplicht in te vullen",
      options: [
        {
          id: "female",
          label: "Vrouw",
        },
        {
          id: "male",
          label: "Man",
        },
        {
          id: "non_binary",
          label: "Non binair",
        },
        {
          id: "prefer_not_to_say",
          label: "Zeg ik liever niet",
        },
        {
          id: "unknown",
          label: "Onbekend",
        },
      ],
      colSpan: "md:col-span-6",
    },
    {
      id: "birthdate",
      label: "Geboortedatum*",
      type: "date",
      required: true,
      errorMessage: "Geboortedatum is verplicht in te vullen",
    },
    {
      id: "postalCode",
      label: "Postcode*",
      type: "text",
      required: true,
      errorMessage: "Postcode is verplicht in te vullen",
      colSpan: "md:col-span-8",
    },
    {
      id: "houseNumber",
      label: "Huisnummer*",
      type: "text",
      required: true,
      errorMessage: "Huisnummer is verplicht in te vullen",
      colSpan: "md:col-span-4",
    },
    {
      id: "street",
      label: "Straat*",
      type: "text",
      required: true,
      errorMessage: "Straat is verplicht in te vullen",
    },

    {
      id: "city",
      label: "Woonplaats*",
      type: "text",
      required: true,
      errorMessage: "Woonplaats is verplicht in te vullen",
    },
    {
      id: "municipality",
      label: "Gemeente*",
      type: "text",
      required: true,
      errorMessage: "Gemeente is verplicht in te vullen",
    },
    {
      id: "province",
      label: "Provincie*",
      type: "select",
      required: true,
      errorMessage: "Provincie is verplicht in te vullen",
      options: provinces?.data
        ? provinces.data.map((province: any) => ({ id: province.id, label: province.name }))
        : [],
    },
    {
      id: "mobilePhone",
      label: "Mobiel telefoonnummer*",
      type: "tel",
      required: true,
      errorMessage: "Mobiel telefoonnummer is verplicht in te vullen",
    },
    {
      id: "phoneNumber",
      label: "Vast telefoonnummer",
      type: "tel",
      errorMessage: "Vast telefoonnummer is verplicht in te vullen",
    },
    {
      id: "membershipType",
      label: "Lidmaatschap",
      type: "radio",
      required: true,
      options: [
        {
          id: "nsc",
          label: "Lidmaatschap NSC (€24,- per jaar)",
        },
        {
          id: "combo",
          label: "Combilidmaatschap NSC en JSC (€24,- per jaar)",
        },
        {
          id: "jsc",
          label: "Lidmaatschap JSC (€5,- per jaar)",
        },
      ],
      description: "Combi- en JSC lidmaatschap zijn alleen beschikbaar voor leden tussen 14 en 30 jaar.",
      errorMessage: "Lidmaatschap is verplicht in te vullen",
    },
    {
      id: "terms",
      label:
        "Ja, ik ga akkoord met het <a href='/privacy-statement' class='underline' target='_blank' rel='noopener noreferrer'>privacy statement</a> en de algemene voorwaarden",
      type: "checkbox",
      required: true,
      errorMessage:
        "Om dit formulier in te vullen, moet je akkoord gaan met het privacy statement en de algemene voorwaarden",
    },
    {
      id: "contact",
      label: "Ik stem er mee in dat ik gecontacteerd wordt over activiteiten voor leden.",
      type: "checkbox",
      required: true,
      errorMessage:
        "Om dit formulier in te vullen, moet je akkoord gaan met dat je gecontacteerd wordt over activiteiten voor leden.",
    },
    {
      id: "newsletter",
      label: "Ja, ik meld mij aan voor de nieuwsbrief en blijf zo op de hoogte van nieuws en evenementen",
      type: "checkbox",
    },
  ];

  return (
    <section className="py-16 md:py-32">
      <div className="grid-container">
        <div className="order-first col-span-12 md:hidden">
          {data?.description && (
            <RichText data={data.description} className="rich-text mb-0 border-[rgba(23,28,96,0.4)] pb-8" />
          )}
        </div>
        <div className="col-span-12 mt-16 md:col-span-6 md:mt-0 lg:col-span-5">
          <h2 className="header-3 mb-8 hidden !text-balance md:block md:text-[42px]">Ja, ik wil lid worden</h2>
          {data?.description && (
            <RichText
              data={data.description}
              className="rich-text mb-0 hidden border-b border-[rgba(23,28,96,0.4)] pb-8 md:block"
            />
          )}
          <Accordion type="single" collapsible className="w-full">
            {data.faq.items.map((item: any) => (
              <AccordionItem key={item.id} value={item.id}>
                <AccordionTrigger className="header-3 !border-none pb-8 data-[state=open]:pb-0">
                  {item.title}
                </AccordionTrigger>
                <AccordionContent>
                  {item?.description && <RichText data={item.description} className="rich-text" />}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="relative left-[-10px] order-first col-span-12 h-fit w-screen bg-nsc-dark-blue px-[10px] py-16 md:left-0 md:order-last md:col-span-6 md:col-start-7 md:w-full md:rounded md:p-10 lg:p-16">
          <h2 className="header-3 mb-8 text-white md:text-[42px]">{data?.formTitle}</h2>
          <DynamicForm
            fields={formFields}
            onSubmit={handleSubmit}
            defaultValues={{
              membershipType: "nsc",
            }}
            buttonLabel="Ja, ik word lid"
            keepForm
          />
        </div>
      </div>
    </section>
  );
};

export default BecomeAMemberSection;
