import * as React from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { cn } from "@/lib/utils";

const Radio = React.forwardRef<
  React.ElementRef<typeof RadioGroup.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroup.Item>
>(({ className, ...props }, ref) => (
  <RadioGroup.Item
    ref={ref}
    className={cn(
      "rounded-full w-[16px] h-[16px] shrink-0 flex items-center justify-center bg-white border border-nsc-dark-blue ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      className
    )}
    {...props}
  >
    <RadioGroup.Indicator
      className={cn(
        "flex items-center justify-center rounded-full",
        "h-[9px] w-[9px] bg-transparent",
        "data-[state=checked]:bg-nsc-dark-blue"
      )}
    />
  </RadioGroup.Item>
));
Radio.displayName = "Radio";

export { Radio };
