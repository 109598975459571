"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cn, getTextColorByBgColor } from "@/lib/utils";
import { Minus, Plus } from "lucide-react";
import Arrow from "@/components/icons/Arrow";

const websiteId = process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc" || "nsc";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn("border-b border-[rgba(23,28,96,0.4)]", className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 gap-4 items-center justify-between py-4 text-start text-xl font-bold transition-all [&[data-state=open]>svg]:!-rotate-90 data-[state=open]:border-b border-[rgba(23,28,96,0.4)] data-[state=open]:mb-5",
        className
      )}
      {...props}
    >
      {children}

      {websiteId === "nsc" ? (
        <Plus className="ml-auto w-5 transition-transform" />
      ) : (
        <Arrow color={props.style?.color ? props.style.color : getTextColorByBgColor()} direction="down"
               className="h-6 w-6 shrink-0 transition-transform duration-200" />
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
